import React, { useState } from "react";
import Carousel, {
  Dots,
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel";
import { Spinner } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import "@brainhubeu/react-carousel/lib/style.css";
import "./SpacesCarousel.css";

export default function SpacesCarousel({ spaces, onImageSelected }) {
  // Carousel logic
  const [value, setValue] = useState(0);
  const onChange = (value) => setValue(value);

  // Image URL Forwarding
  const onCardClicked = (imageUrl) => {
    onImageSelected(
      imageUrl.includes("http")
        ? imageUrl
        : process.env.REACT_APP_FILE_SERVER + imageUrl
    );
  };

  // Card Component
  const makeCard = (space) => {
    return (
      <div
        key={space.id}
        className="leuk-carousel-item"
        onClick={() => onCardClicked(space.image_url)}
      >
        <div
          className="item-image"
          style={{
            backgroundImage: `url(${
              space.image_url.includes("http")
                ? space.image_url
                : process.env.REACT_APP_FILE_SERVER + space.image_url
            })`,
          }}
        ></div>
        <p>{space.name}</p>
      </div>
    );
  };

  return spaces ? (
    <>
      <Carousel
        value={value}
        onChange={onChange}
        className="leuk-carousel"
        plugins={[
          "centered",
          "infinite",
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 4,
            },
          },
          {
            resolve: arrowsPlugin,
            options: {
              arrowLeft: (
                <button className="carousel-control left">
                  <ChevronLeft />
                </button>
              ),
              arrowLeftDisabled: (
                <button className="carousel-control left">
                  <ChevronLeft />
                </button>
              ),
              arrowRight: (
                <button className="carousel-control right">
                  <ChevronRight />
                </button>
              ),
              arrowRightDisabled: (
                <button className="carousel-control right">
                  <ChevronRight />
                </button>
              ),
              addArrowClickHandler: true,
            },
          },
        ]}
        breakpoints={{
          600: {
            plugins: [
              "centered",
              "infinite",
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1.4,
                },
              },
            ],
          },
          1024: {
            plugins: [
              "centered",
              "infinite",
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 3.8,
                },
              },
            ],
          },
        }}
      >
        {spaces.map(makeCard)}
      </Carousel>
      <Dots value={value} onChange={onChange} number={spaces.length}></Dots>
    </>
  ) : (
    <Spinner animation="grow" variant="dark" size="xl" className="spinner" />
  );
}
