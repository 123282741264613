import React, { useRef, useEffect, useState } from "react";
import { InputGroup, Form, Spinner } from "react-bootstrap";
import {
  Search,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  HouseDoorFill,
  ArrowCounterclockwise,
  Lightbulb,
} from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";
import "./LeukSimulatorSelector.css";

export default function LeukSimulatorSelector({
  simulator,
  filteredItemList,
  currentItem,
  currentLevel,
  selectItem,
  resetSelectedItem,
  loading,
  searchTerm,
  filterCurrentList,
  canRotate,
  onRotate,
  open,
  onOpen,
  showSubCategoryInBreadcrumb,
}) {
  const placeholders = {
    categories: "Categorías",
    subcategories: "Sub Categorías",
    lamps: "Lámparas",
  };

  const scrollContainerRef = useRef(null);

  const makeSelectorItem = (item) => {
    return (
      <li
        onClick={() => selectItem(item, currentLevel)}
        key={item.id + currentLevel}
      >
        <img
          src={
            item.image_url.includes("http")
              ? item.image_url
              : process.env.REACT_APP_FILE_SERVER + item.image_url
          }
          alt={item.name}
        />
        <p>{item.name}</p>
      </li>
    );
  };

  const makeBreadcrumbs = () => {
    switch (currentLevel) {
      case "subcategories":
        return (
          <>
            <a onClick={resetSelectedItem}>
              <HouseDoorFill />
            </a>
            <ChevronRight />
            <a>{currentItem.category.name}</a>
          </>
        );
      case "lamps":
        return (
          <>
            <a onClick={resetSelectedItem}>
              <HouseDoorFill />
            </a>
            <ChevronRight />
            <a onClick={() => selectItem(currentItem.category, "categories")}>
              {currentItem.category.name}
            </a>
            {showSubCategoryInBreadcrumb && (
              <>
                <ChevronRight />
                <a>{currentItem.subCategory.name}</a>
              </>
            )}
          </>
        );
      default:
        return;
    }
  };

  const onScroll = (direction) => {
    const currentScroll = scrollContainerRef.current.scrollLeft;
    const containerWidth = scrollContainerRef.current.clientWidth;
    const maxScroll = scrollContainerRef.current.scrollWidth - containerWidth;
    const minScroll = 0;
    const itemWidth =
      scrollContainerRef.current.querySelector("li").clientWidth;
    const pageWidthToScroll =
      (Math.round(containerWidth / itemWidth) - 1) * itemWidth;
    let remainingScroll = 0;
    if (direction === 1) {
      // Scrolling right
      remainingScroll = maxScroll - currentScroll;
    } else {
      // Scrolling left
      remainingScroll = currentScroll;
    }
    const amountToScroll = Math.min(pageWidthToScroll, remainingScroll);
    if (amountToScroll > 0)
      scrollContainerRef.current.scroll({
        top: 0,
        left: currentScroll + direction * amountToScroll,
        behavior: "smooth",
      });
  };

  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    if (!loading) {
      setHasScroll(
        scrollContainerRef.current.clientWidth !==
          scrollContainerRef.current.scrollWidth
      );
    }
  }, null);

  return (
    <div className={`leuk-selector ${open ? "open" : ""}`}>
      <div
        className="leuk-selector-tab"
        onClick={onOpen}
        style={{
          backgroundColor: simulator.main_color,
          color: simulator.secondary_color,
        }}
      >
        {open ? <ChevronDown /> : <Lightbulb />}
      </div>
      {canRotate && (
        <div className="rotate" onClick={onRotate}>
          <ArrowCounterclockwise />
        </div>
      )}
      {!loading ? (
        <>
          <div className="breadcrumbs">{makeBreadcrumbs()}</div>
          <InputGroup className="leuk-search">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <Search />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              value={searchTerm}
              placeholder={placeholders[currentLevel] + "..."}
              onChange={(e) => filterCurrentList(e.target.value)}
            />
          </InputGroup>
          <ul className={!isMobile ? "desktop" : ""} ref={scrollContainerRef}>
            {filteredItemList.map(makeSelectorItem)}
          </ul>
          {!isMobile && hasScroll ? (
            <div className="scroll-controls">
              <button
                className="scroll-control scroll-left"
                onClick={() => onScroll(-1)}
              >
                <ChevronLeft />
              </button>
              <button
                className="scroll-control scroll-right"
                onClick={() => onScroll(1)}
              >
                <ChevronRight />
              </button>
            </div>
          ) : null}
        </>
      ) : (
        <div className="loading">
          <Spinner
            animation="grow"
            variant="dark"
            size="sm"
            className="spinner"
          />
        </div>
      )}
    </div>
  );
}
