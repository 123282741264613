import React from "react";
import {
  BoxArrowUp,
  Download,
  ArrowClockwise,
  SymmetryVertical,
  TrashFill,
  FilePlus,
} from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";
import "./LeukLampControls.css";

export default function LeukLampControls({
  simulator,
  onShare,
  onCopy,
  onRemove,
  onReflect,
  onRotate,
  active,
  open,
}) {
  return (
    <div
      className={`leuk-lamp-controls ${open ? "open" : ""}`}
      style={{
        backgroundColor: simulator.main_color,
        color: simulator.secondary_color,
      }}
    >
      <div className="leuk-lamp-controls-container">
        <div className="leuk-lamp-control" onClick={onShare}>
          {isMobile ? (
            <>
              <BoxArrowUp />
              <p>Compartir</p>
            </>
          ) : (
            <>
              <Download />
              <p>Descargar</p>
            </>
          )}
        </div>
        <div
          className={`leuk-lamp-control ${active ? "" : "inactive"}`}
          onClick={onCopy}
        >
          <FilePlus />
          <p>Duplicar</p>
        </div>
        <div
          className={`leuk-lamp-control ${active ? "" : "inactive"}`}
          onClick={onRemove}
        >
          <TrashFill />
          <p>Borrar</p>
        </div>
        <div
          className={`leuk-lamp-control ${active ? "" : "inactive"}`}
          onClick={onReflect}
        >
          <SymmetryVertical />
          <p>Reflejar</p>
        </div>
        <div
          className={`leuk-lamp-control ${active ? "" : "inactive"}`}
          onClick={onRotate}
        >
          <ArrowClockwise />
          <p>Rotar 90º</p>
        </div>
      </div>
    </div>
  );
}
