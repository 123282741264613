import React from "react";
import leukAPI from "../../api/leukAPI";
import { useState, useEffect } from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AdminPage from "../components/AdminPage";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import ActionButton from "../../components/ActionButton/ActionButton";
import SearchBar from "../../components/SearchBar/SearchBar";

export default function Spaces() {
  const [spaces, setSpaces] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  useEffect(() => {
    leukAPI.get("spaces").then((response) => setSpaces(response.data));
  }, []);

  const history = useHistory();
  const deleteSpace = (id) => {
    // delete and refresh
    setSpaces(false);
    leukAPI.delete(`spaces/${id}`).then((res) => {
      leukAPI.get("spaces").then((response) => setSpaces(response.data));
    });
  };

  const loadPage = (url) => {
    leukAPI.get(url).then((response) => setSpaces(response.data));
  };

  const onSearchAction = (value) => {
    setSearching(true);
    if (value !== "") {
      // Search for value
      setSearchParam(value);
      leukAPI.get(`spaces/search?name=${value}`).then((response) => {
        setSpaces(response.data);
        setSearching(false);
      });
    } else {
      // Reload first page
      setSearchParam("");
      leukAPI.get("spaces").then((response) => {
        setSpaces(response.data);
        setSearching(false);
      });
    }
  };
  const makeContent = () => {
    return (
      spaces && (
        <>
          <h1>Espacios</h1>
          <ActionButton
            onClick={() => history.push("spaces/new")}
          ></ActionButton>
          <Row className="justify-content-center mb-2 mt-5">
            <Col md={5}>
              <SearchBar onSearchAction={onSearchAction} />
            </Col>
          </Row>
          {!searching ? (
            <DynamicTable
              data={spaces.data.map((space) => {
                return {
                  id: space.id,
                  title: space.name,
                  subtitle: "",
                  avatar: space.image_url,
                };
              })}
              meta={spaces}
              parentRoute="spaces"
              resourceLabel="Espacios"
              handleDeleteResource={deleteSpace}
              onPageSelected={loadPage}
              searchParam={searchParam}
            ></DynamicTable>
          ) : (
            <Spinner
              animation="grow"
              variant="dark"
              size="xl"
              className="spinner"
            />
          )}
        </>
      )
    );
  };

  return <AdminPage>{makeContent()}</AdminPage>;
}
