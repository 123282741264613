import React from 'react';
import { PlusLg } from "react-bootstrap-icons"
import "./ActionButton.css";

export default function ActionButton({ onClick }) {
    return (
        <button className="action-button bg-dark" onClick={onClick}>
            <PlusLg className="align-middle"></PlusLg>
        </button>
    );
}
