import React, { useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useHistory, Redirect } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import leukAPI from "../../api/leukAPI";

export default function AdminPage({ children }) {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  return isLoggedIn ? (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        collapseOnSelect
        expand="md"
        style={{ zIndex: 2 }}
      >
        <Navbar.Brand onClick={() => history.push("/")}>
          Leuk Simulador
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link onClick={() => history.push("/categories")}>
              Categorías
            </Nav.Link>

            <Nav.Link onClick={() => history.push("/subcategories")}>
              Sub Categorías
            </Nav.Link>

            <Nav.Link onClick={() => history.push("/lamps")}>Lámparas</Nav.Link>
            <Nav.Link onClick={() => history.push("/spaces")}>
              Espacios
            </Nav.Link>
            <Nav.Link onClick={() => history.push("/simulators")}>
              Simuladores
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            <Nav.Link
              onClick={() => {
                console.log("Logging out!");
                leukAPI.delete("logout").then((res) => {
                  console.log("clearing token and redirecting");
                  localStorage.removeItem("token");
                  setIsLoggedIn(false);
                });
              }}
            >
              Log out
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container>
        {children || (
          <Spinner
            animation="grow"
            variant="dark"
            size="xl"
            className="spinner"
          />
        )}
      </Container>
    </>
  ) : (
    <Redirect to="/login"></Redirect>
  );
}
