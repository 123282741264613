import React from "react";
import AdminPage from "../components/AdminPage";
import leukAPI from "../../api/leukAPI";
import { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Image, Modal, Alert } from "react-bootstrap";
import {
  CloudUpload,
  Trash,
  CardImage,
  QuestionLg,
} from "react-bootstrap-icons";
import { useParams, useHistory } from "react-router-dom";
import "./Lamp.css";

export default function Lamp() {
  const { id } = useParams();
  const history = useHistory();
  const [lamp, setLamp] = useState(false);
  const [name, setName] = useState("");
  const [height, setHeight] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("---");
  const [subCategories, setSubCategories] = useState(false);
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const showSuccessMessage = (message) => {
    setAlertMessage(message);
    setAlertVariant("success");
    setShowAlert(true);
  };

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearInputs = () => {
    setImage(false);
    setName("");
    setDescription("");
    setHeight("");
    setSelectedSubCategory("---");
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
        setImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id === "new") {
      if (
        !name ||
        !height ||
        selectedSubCategory === "---" ||
        !image ||
        uploadedImage.current.file.type !== "image/png"
      ) {
        handleShow();
        return;
      }
      let data = new FormData();
      data.set(
        "image_file",
        uploadedImage.current.file,
        uploadedImage.current.file.name
      );
      data.set("name", name);
      data.set("height", height);
      data.set("description", description);
      data.set("sub_category_id", selectedSubCategory);

      // create a new lamp
      leukAPI.post("lamps", data).then((res) => {
        if (res.status === 201) {
          showSuccessMessage("Lámpara añadida con éxito!");
          clearInputs();
        }
      });
    } else {
      // Update an existing one
      if (
        !name ||
        !height ||
        selectedSubCategory === "---" ||
        !image ||
        (uploadedImage.current.file &&
          uploadedImage.current.file.type !== "image/png")
      ) {
        handleShow();
        return;
      }
      let data = new FormData();
      if (uploadedImage.current.file) {
        data.set(
          "image_file",
          uploadedImage.current.file,
          uploadedImage.current.file.name
        );
        // reset the uploaded image so that it doesn't get re uploaded if more changes are done
        uploadedImage.current.file = undefined;
      }
      data.set("name", name);
      data.set("height", height);
      data.set("description", description);
      data.set("sub_category_id", selectedSubCategory);
      leukAPI.post(`lamps/${id}`, data).then((res) => {
        if (res.status === 200) {
          setLamp(res.data);
          setName(res.data.name);
          setHeight(res.data.height);
          setDescription(res.data.description);
          setSelectedSubCategory(res.data.sub_category_id);
          showSuccessMessage("Lámpara editada con éxito!");
        }
      });
    }
  };

  useEffect(() => {
    if (id === "new") {
      setLamp(true);
      setImage(false);
      return;
    }
    leukAPI.get(`lamps/${id}`).then((response) => {
      setLamp(response.data);
      setName(response.data.name);
      setHeight(response.data.height);
      setDescription(response.data.description);
      setSelectedSubCategory(response.data.sub_category_id);
      setImage(true);
    });
  }, [id]);

  useEffect(() => {
    leukAPI
      .get("sub_categories?all=slim")
      .then((response) => setSubCategories(response.data));
  }, []);

  const makeContent = () => {
    return lamp && subCategories ? (
      <>
        <Row>
          <Col>
            <h1>{lamp === true ? "Nueva Lámpara" : lamp.name}</h1>
            <h2>{lamp?.subcategory?.name}</h2>
            {showAlert && (
              <Alert
                variant={alertVariant}
                onClose={() => setShowAlert(false)}
                dismissible
              >
                {alertMessage}
              </Alert>
            )}
          </Col>
        </Row>
        <Form>
          <Form.Row>
            <Form.Group as={Col} className="image-container" md={6}>
              <Image
                src={
                  lamp.image_url && lamp.image_url.includes("http")
                    ? lamp.image_url
                    : process.env.REACT_APP_FILE_SERVER + lamp.image_url
                }
                ref={uploadedImage}
                className={!image ? "hide" : ""}
              ></Image>

              <div className={image ? "hide" : "image-placeholder"}>
                <div>
                  <CardImage size={100} />
                  <QuestionLg size={100} />
                </div>

                <div className="image-placeholder-message">No hay imagen</div>
              </div>

              <div className="image-controls">
                <input
                  type="file"
                  accept="image/png"
                  multiple={false}
                  ref={imageUploader}
                  onChange={handleImageUpload}
                ></input>
                <Button onClick={() => imageUploader.current.click()}>
                  <CloudUpload></CloudUpload> Subir
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    setImage(null);
                    imageUploader.current.value = null;
                  }}
                >
                  <Trash></Trash> Borrar
                </Button>
              </div>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Sub Categoría</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                  value={selectedSubCategory}
                >
                  <option value="---">---</option>
                  {subCategories.map((subCategory) => (
                    <option
                      key={subCategory.id}
                      value={subCategory.id}
                    >{`${subCategory.name} - Categoría ${subCategory.category.name}`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Altura (cm)</Form.Label>
                <Form.Control
                  type="number"
                  step=".1"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={description}
                  placeholder="Descripción"
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button
                variant="dark"
                className="float-right"
                type="submit"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
              <Button
                variant="light"
                className="float-right mr-2"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Es necesario proveer una imagen .png, nombre, altura y subcategoría
            válidos para cargar una lámpara.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    ) : (
      false
    );
  };

  return <AdminPage>{makeContent()}</AdminPage>;
}
