import React from "react";
import "./LeukSimulatorNavbar.css";
import { ArrowLeftShort, List, XLg } from "react-bootstrap-icons";
import LeukLampControls from "../LeukLampControls/LeukLampControls";

export default function LeukSimulatorNavbar({
  simulator,
  goBack,
  onShare,
  canShare = false,
  reset = false,
  show = true,
  open,
  active,
  onOpen,
  onRemove,
  onCopy,
  onReflect,
  onRotate,
}) {
  return (
    <>
      <div
        className={`leuk-navbar${show ? "" : " hide"}`}
        style={{ backgroundColor: simulator.main_color }}
      >
        <div
          className="navbar-left"
          style={{ color: simulator.secondary_color }}
        >
          {goBack && (
            <div>
              <ArrowLeftShort onClick={goBack} />
            </div>
          )}
        </div>
        <div className="navbar-center">
          <img
            src={process.env.REACT_APP_FILE_SERVER + simulator.logo}
            onClick={typeof reset === "function" ? reset : null}
            alt="logo"
          />
        </div>
        <div
          className="navbar-right"
          style={{ color: simulator.secondary_color }}
        >
          <div className="actions-container">
            {canShare &&
              (open ? <XLg onClick={onOpen} /> : <List onClick={onOpen} />)}
          </div>
        </div>
      </div>
      <LeukLampControls
        simulator={simulator}
        active={active}
        onShare={onShare}
        onRemove={onRemove}
        onCopy={onCopy}
        onReflect={onReflect}
        onRotate={onRotate}
        open={open}
        onOpen={onOpen}
      />
    </>
  );
}
