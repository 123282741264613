import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminPage from "../components/AdminPage";
import { Row, Col, Spinner } from "react-bootstrap";
import InventoryManager from "../../components/InventoryManager/InventoryManager";
import leukAPI from "../../api/leukAPI";

export default function SimulatorLamp() {
  const { id } = useParams();

  const [simulator, setSimulator] = useState(null);

  useEffect(() => {
    leukAPI.get(`simulators/${id}`).then((res) => {
      setSimulator(res.data);
    });
  }, [id]);

  const makeContent = () => {
    return simulator ? (
      <>
        <Row className="mb-4">
          <Col>
            <h1>{simulator.name}</h1>
            <h4>Asignación de Lámparas</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <InventoryManager simulator_id={id} />
          </Col>
        </Row>
      </>
    ) : (
      <Spinner animation="grow" variant="dark" size="xl" className="spinner" />
    );
  };

  return <AdminPage>{makeContent()}</AdminPage>;
}
