import React from "react";
import AdminPage from "../components/AdminPage";
import leukAPI from "../../api/leukAPI";
import { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Image, Modal, Alert } from "react-bootstrap";
import {
  CloudUpload,
  Trash,
  CardImage,
  QuestionLg,
} from "react-bootstrap-icons";
import { useParams, useHistory } from "react-router-dom";
import "./Space.css";

export default function Lamp() {
  const { id } = useParams();
  const history = useHistory();
  const [space, setSpace] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const showSuccessMessage = (message) => {
    setAlertMessage(message);
    setAlertVariant("success");
    setShowAlert(true);
  };

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearInputs = () => {
    setImage(false);
    setName("");
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
        setImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id === "new") {
      if (
        !name ||
        !image ||
        !["image/png", "image/jpg", "image/jpeg"].includes(
          uploadedImage.current.file.type
        )
      ) {
        handleShow();
        return;
      }
      let data = new FormData();
      data.set(
        "image_file",
        uploadedImage.current.file,
        uploadedImage.current.file.name
      );
      data.set("name", name);

      // create a new space
      leukAPI.post("spaces", data).then((res) => {
        if (res.status === 201) {
          showSuccessMessage("Espacio añadido con éxito!");
          clearInputs();
        }
      });
    } else {
      // Update an existing one
      if (
        !name ||
        !image ||
        (uploadedImage.current.file &&
          !["image/png", "image/jpg", "image/jpeg"].includes(
            uploadedImage.current.file.type
          ))
      ) {
        handleShow();
        return;
      }
      let data = new FormData();
      if (uploadedImage.current.file) {
        data.set(
          "image_file",
          uploadedImage.current.file,
          uploadedImage.current.file.name
        );
        // reset the uploaded image so that it doesn't get re uploaded if more changes are done
        uploadedImage.current.file = undefined;
      }
      data.set("name", name);
      leukAPI.post(`spaces/${id}`, data).then((res) => {
        if (res.status === 200) {
          setSpace(res.data);
          setName(res.data.name);
          showSuccessMessage("Espacio editado con éxito!");
        }
      });
    }
  };

  useEffect(() => {
    if (id === "new") {
      setSpace(true);
      setImage(false);
      return;
    }
    leukAPI.get(`spaces/${id}`).then((response) => {
      setSpace(response.data);
      setName(response.data.name);
      setImage(true);
    });
  }, [id]);

  const makeContent = () => {
    return space ? (
      <>
        <Row>
          <Col>
            <h1>{space === true ? "Nuevo Espacio" : space.name}</h1>
            {showAlert && (
              <Alert
                variant={alertVariant}
                onClose={() => setShowAlert(false)}
                dismissible
              >
                {alertMessage}
              </Alert>
            )}
          </Col>
        </Row>
        <Form>
          <Form.Row>
            <Form.Group as={Col} className="image-container" md={6}>
              <Image
                src={
                  space.image_url && space.image_url.includes("http")
                    ? space.image_url
                    : process.env.REACT_APP_FILE_SERVER + space.image_url
                }
                ref={uploadedImage}
                className={!image ? "hide" : ""}
              ></Image>

              <div className={image ? "hide" : "image-placeholder"}>
                <div>
                  <CardImage size={100} />
                  <QuestionLg size={100} />
                </div>

                <div className="image-placeholder-message">No hay imagen</div>
              </div>

              <div className="image-controls">
                <input
                  type="file"
                  accept="image/png,image/jpeg,image/png"
                  multiple={false}
                  ref={imageUploader}
                  onChange={handleImageUpload}
                ></input>
                <Button onClick={() => imageUploader.current.click()}>
                  <CloudUpload></CloudUpload> Subir
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    setImage(null);
                    imageUploader.current.value = null;
                  }}
                >
                  <Trash></Trash> Borrar
                </Button>
              </div>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button
                variant="dark"
                className="float-right"
                type="submit"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
              <Button
                variant="light"
                className="float-right mr-2"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Es necesario proveer una imagen (.png o .jpg) y nombre válidos para
            cargar un espacio.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    ) : (
      false
    );
  };

  return <AdminPage>{makeContent()}</AdminPage>;
}
