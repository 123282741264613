import React from "react";
import AdminPage from "../components/AdminPage";
import leukAPI from "../../api/leukAPI";
import { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Image, Modal, Alert } from "react-bootstrap";
import {
  CloudUpload,
  Trash,
  CardImage,
  QuestionLg,
} from "react-bootstrap-icons";
import { useParams, useHistory } from "react-router-dom";
import "./SubCategory.css";

export default function SubCategory() {
  const { id } = useParams();
  const history = useHistory();
  const [subCategory, setSubCategory] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("---");
  const [categories, setCategories] = useState(false);
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const showSuccessMessage = (message) => {
    setAlertMessage(message);
    setAlertVariant("success");
    setShowAlert(true);
  };

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearInputs = () => {
    setImage(false);
    setName("");
    setDescription("");
    setSelectedCategory("---");
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
        setImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id === "new") {
      if (
        !name ||
        selectedCategory === "---" ||
        !image ||
        uploadedImage.current.file.type !== "image/png"
      ) {
        handleShow();
        return;
      }
      let data = new FormData();
      data.set(
        "image_file",
        uploadedImage.current.file,
        uploadedImage.current.file.name
      );
      data.set("name", name);
      data.set("description", description);
      data.set("category_id", selectedCategory);

      // create a new lamp
      leukAPI.post("sub_categories", data).then((res) => {
        if (res.status === 201) {
          showSuccessMessage("Sub Categoría añadida con éxito!");
          clearInputs();
        }
      });
    } else {
      // Update an existing one
      if (
        !name ||
        selectedCategory === "---" ||
        !image ||
        (uploadedImage.current.file &&
          uploadedImage.current.file.type !== "image/png")
      ) {
        handleShow();
        return;
      }
      let data = new FormData();
      if (uploadedImage.current.file) {
        data.set(
          "image_file",
          uploadedImage.current.file,
          uploadedImage.current.file.name
        );
        // reset the uploaded image so that it doesn't get re uploaded if more changes are done
        uploadedImage.current.file = undefined;
      }
      data.set("name", name);
      data.set("description", description);
      data.set("category_id", selectedCategory);
      leukAPI.post(`sub_categories/${id}`, data).then((res) => {
        if (res.status === 200) {
          setSubCategory(res.data);
          setName(res.data.name);
          setDescription(res.data.description);
          setSelectedCategory(res.data.category_id);
          showSuccessMessage("Sub Categoría editada con éxito!");
        }
      });
    }
  };

  useEffect(() => {
    if (id === "new") {
      setSubCategory(true);
      setImage(false);
      return;
    }
    console.log("Retrieving lamp");
    leukAPI.get(`sub_categories/${id}`).then((response) => {
      setSubCategory(response.data);
      setName(response.data.name);
      setDescription(response.data.description);
      setSelectedCategory(response.data.category_id);
      setImage(true);
    });
  }, [id]);

  useEffect(() => {
    leukAPI
      .get("categories?all=slim")
      .then((response) => setCategories(response.data));
  }, []);

  const makeContent = () => {
    return subCategory && categories ? (
      <>
        <Row>
          <Col>
            <h1>
              {subCategory === true ? "Nueva Sub Categoría" : subCategory.name}
            </h1>
            <h2>{subCategory?.category?.name}</h2>
            {showAlert && (
              <Alert
                variant={alertVariant}
                onClose={() => setShowAlert(false)}
                dismissible
              >
                {alertMessage}
              </Alert>
            )}
          </Col>
        </Row>
        <Form>
          <Form.Row>
            <Form.Group as={Col} className="image-container" md={6}>
              <Image
                src={
                  subCategory.image_url &&
                  subCategory.image_url.includes("http")
                    ? subCategory.image_url
                    : process.env.REACT_APP_FILE_SERVER + subCategory.image_url
                }
                ref={uploadedImage}
                className={!image ? "hide" : ""}
              ></Image>

              <div className={image ? "hide" : "image-placeholder"}>
                <div>
                  <CardImage size={100} />
                  <QuestionLg size={100} />
                </div>

                <div className="image-placeholder-message">No hay imagen</div>
              </div>

              <div className="image-controls">
                <input
                  type="file"
                  accept="image/png"
                  multiple={false}
                  ref={imageUploader}
                  onChange={handleImageUpload}
                ></input>
                <Button onClick={() => imageUploader.current.click()}>
                  <CloudUpload></CloudUpload> Subir
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    setImage(null);
                    imageUploader.current.value = null;
                  }}
                >
                  <Trash></Trash> Borrar
                </Button>
              </div>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Categoría</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  value={selectedCategory}
                >
                  <option value="---">---</option>
                  {categories.map((category) => (
                    <option
                      key={category.id}
                      value={category.id}
                    >{`${category.name}`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button
                variant="dark"
                className="float-right"
                type="submit"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
              <Button
                variant="light"
                className="float-right mr-2"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Es necesario proveer una imagen .png, categoría y nombre válidos
            para cargar una subcategoría.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    ) : (
      false
    );
  };

  return <AdminPage>{makeContent()}</AdminPage>;
}
