import React, { useState, useEffect, useRef } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { fabric } from "fabric";
import { Modal } from "react-bootstrap";
import Webcam from "react-webcam";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LeukSimulatorNavbar from "../LeukSimulatorNavbar/LeukSimulatorNavbar";
import LeukSimulatorSelector from "../LeukSimulatorSelector/LeukSimulatorSelector";
import LeukFullPageLoader from "../LeukFullPageLoader/LeukFullPageLoader";
import simulatorAPI from "../../api/simulatorAPI";
import "./LeukSimulatorStep2.css";

export default function LeukSimulatorStep2({
  simulator,
  backgroundURL,
  useCamera,
  setStep,
}) {
  // Selector State
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [lamps, setLamps] = useState({});
  const [currentItemList, setCurrentItemList] = useState([]);
  const [filteredItemList, setFilteredItemList] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentLevel, setCurrentLevel] = useState("categories");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectorOpen, setSelectorOpen] = useState(true);
  const [showSubCategoryInBreadcrumb, setShowSubCategoryInBreadcrumb] =
    useState(true);

  const [selectorAutoOpen, setSelectorAutoOpen] = useState(false);

  useEffect(() => {
    simulatorAPI.get(`${simulator.id}/categories?all=slim`).then((res) => {
      setCategories(res.data);
      setCurrentItemList(res.data);
      setFilteredItemList(res.data);
      setLoading(false);
      setSelectorAutoOpen(true);
    });
  }, [simulator.id]);

  const filterCurrentList = (value) => {
    setSearchTerm(value);
    if (value === "") {
      setFilteredItemList(currentItemList);
    } else {
      setFilteredItemList(
        currentItemList.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const resetSelectedItem = () => {
    setCurrentItem(null);
    setCurrentItemList(categories);
    setFilteredItemList(categories);
    setCurrentLevel("categories");
  };

  const onLampSelected = (lamp) => {
    setShowFullPageLoader(true);
    const lampUrl = lamp.image_url.includes("http")
      ? lamp.image_url
      : process.env.REACT_APP_FILE_SERVER + lamp.image_url;
    new fabric.Image.fromURL(
      lampUrl,
      (lampImg) => {
        // SCALING LOGIC (DEPRECATED FOR NOW)
        // We take the background height and assume its 5m
        // const bgHeight = background.getScaledHeight();
        // const lampScaledHeight = (lamp.height * bgHeight) / 500;
        // lampImg.scaleToHeight(lampScaledHeight);
        // Lamps enter with half the canvas height and centered.
        lampImg.scaleToHeight(canvas.height / 3);
        addLamp(lampImg);
      },
      { crossOrigin: "Anonymous" }
    );
  };

  const selectItem = async (item, level) => {
    let newList;
    setLoading(true);
    switch (level) {
      case "categories":
        if (item.id in subCategories) {
          console.log("soft load");
          newList = subCategories[item.id];
        } else {
          console.log("hard load");
          const res = await simulatorAPI.get(
            `${simulator.id}/categories/${item.id}/sub_categories?all=slim`
          );
          newList = res.data;
          let newSubCategories = { ...subCategories };
          newSubCategories[item.id] = newList;
          setSubCategories(newSubCategories);
        }
        // Logic for categories that have a single subcategory with same name as category
        const subCat = newList[0];
        if (newList.length === 1 && subCat.name === item.name) {
          if (subCat.id in lamps) {
            console.log("soft load");
            newList = lamps[subCat.id];
          } else {
            console.log("hard load");
            const res = await simulatorAPI.get(
              `${simulator.id}/sub_categories/${subCat.id}/lamps?all=slim`
            );
            newList = res.data;
            let newLamps = { ...lamps };
            newLamps[subCat.id] = newList;
            setLamps(newLamps);
          }
          setCurrentItem({
            category: {
              id: item.id,
              name: item.name,
            },
            subCategory: {
              id: subCat.id,
              name: subCat.name,
            },
          });
          setCurrentItemList(newList);
          setFilteredItemList(newList);
          setCurrentLevel("lamps");
          setShowSubCategoryInBreadcrumb(false);
        } else {
          setCurrentItem({ category: { id: item.id, name: item.name } });
          setCurrentItemList(newList);
          setFilteredItemList(newList);
          setCurrentLevel("subcategories");
          setShowSubCategoryInBreadcrumb(true);
        }

        break;

      case "subcategories":
        if (item.id in lamps) {
          console.log("soft load");
          newList = lamps[item.id];
        } else {
          console.log("hard load");
          const res = await simulatorAPI.get(
            `${simulator.id}/sub_categories/${item.id}/lamps?all=slim`
          );
          newList = res.data;
          let newLamps = { ...lamps };
          newLamps[item.id] = newList;
          setLamps(newLamps);
        }
        setCurrentItem({
          ...currentItem,
          subCategory: { id: item.id, name: item.name },
        });
        setCurrentItemList(newList);
        setFilteredItemList(newList);
        setCurrentLevel("lamps");
        break;
      case "lamps":
        onLampSelected(item);
        break;

      default:
        break;
    }
    setLoading(false);
    setSearchTerm("");
  };

  // Canvas State
  const [canvas, setCanvas] = useState(null);
  const [background, setBackground] = useState(null);
  const { height, width } = useWindowDimensions();
  const [currentLamp, setCurrentLamp] = useState(null);
  const [placedLamps, setPlacedLamps] = useState([]);
  const backgroundTop = useRef(null);
  const backgroundLeft = useRef(null);

  const canvasWidth = width;
  const canvasHeight = height - 65;
  // width > 1024 ? height - 445 : width > 768 ? height - 355 : height - 325;

  // Initialize canvas
  useEffect(() => {
    console.log("initialize canvas");
    const fCanvas = new fabric.Canvas("canvas", {
      height: canvasHeight,
      width: canvasWidth,
      preserveObjectStacking: true,
      selection: false,
    });
    setCanvas(fCanvas);

    // When using a camera, we don't initalize the background
    if (useCamera) return;

    console.log("initialize image");
    new fabric.Image.fromURL(
      backgroundURL,
      (bg) => {
        bg.originY = "top";
        bg.originX = "left";
        const verticallyConstrained =
          fCanvas.width / fCanvas.height < bg.width / bg.height;
        if (verticallyConstrained) {
          console.log("vertical constraint");
          bg.scaleToHeight(fCanvas.height);
          bg.lockMovementY = true;
          bg.set({ top: 0, left: -(bg.getScaledWidth() - fCanvas.width) / 2 });
          backgroundTop.current = 0;
          backgroundLeft.current = -(bg.getScaledWidth() - fCanvas.width) / 2;
        } else {
          console.log("horizontal constraint");
          bg.scaleToWidth(fCanvas.width);
          bg.lockMovementX = true;
          backgroundTop.current = 0;
          backgroundLeft.current = 0;
        }
        // Disable Scaling and rotating on the background
        bg.lockScalingX = true;
        bg.lockScalingY = true;
        bg.lockRotation = true;
        bg.hasControls = false;
        setBackground(bg);
        fCanvas.add(bg);
        bg.on("mouseup", onSelectBackground);
        // Background Displacement Logic
        fCanvas.on("object:moving", (e) => {
          // Event handler for moving the background
          if (e.target !== bg) return;
          const verticallyConstrained =
            fCanvas.width / fCanvas.height < bg.width / bg.height;
          if (verticallyConstrained) {
            console.log("vertically constrained movement");
            if (e.target.left > 0) {
              // If the user is trying to get the left from the right, prevent it
              e.target.left = 0;
            } else if (
              e.target.left < -(e.target.getScaledWidth() - fCanvas.width)
            ) {
              // If the user is trying to get the right from the left, prevent it
              e.target.left = -(e.target.getScaledWidth() - fCanvas.width);
            }
          } else {
            console.log("horizontally constrained movement");
            if (e.target.top > 0) {
              // If the user is trying to get the top down, prevent it
              e.target.top = 0;
            } else if (
              e.target.top < -(e.target.getScaledHeight() - fCanvas.height)
            ) {
              // If the user is trying to get the bottom up, prevent it
              e.target.top = -(e.target.getScaledHeight() - fCanvas.height);
            }
          }
          // Apply deltas to all lamps if any
          const deltaX = e.target.left - backgroundLeft.current;
          const deltaY = e.target.top - backgroundTop.current;
          fCanvas.getObjects().forEach((obj) => {
            if (obj !== bg) {
              obj.left += deltaX;
              obj.top += deltaY;
              obj.setCoords();
            }
          });
          // Update new values
          backgroundLeft.current = e.target.left;
          backgroundTop.current = e.target.top;
        });

        fCanvas.renderAll();
      },
      { crossOrigin: "Anonymous" }
    );
  }, []);

  // Webcam logic
  const webcamRef = useRef(null);
  const videoConstraints = {
    facingMode: { exact: "environment" },
    resizeMode: "crop-and-scale",
  };

  useEffect(() => {
    const frameRate = 30;
    let frameRateTimeout;
    if (useCamera && canvas && webcamRef) {
      frameRateTimeout = setTimeout(() => {
        const screenshot = webcamRef.current.getScreenshot();
        new fabric.Image.fromURL(screenshot, (webcam) => {
          webcam.lockScalingX = true;
          webcam.lockScalingY = true;
          webcam.lockRotation = true;
          webcam.hasControls = false;
          webcam.originY = "top";
          webcam.originX = "left";
          const verticallyConstrained =
            canvas.width / canvas.height < webcam.width / webcam.height;
          if (verticallyConstrained) {
            webcam.scaleToHeight(canvas.height);
            webcam.set({
              top: 0,
              left: -(webcam.getScaledWidth() - canvas.width) / 2,
            });
          } else {
            webcam.scaleToWidth(canvas.width);
          }
          webcam.lockMovementY = true;
          webcam.lockMovementX = true;
          webcam.on("mouseup", onSelectBackground);
          canvas.remove(background);
          canvas.add(webcam);
          canvas.sendToBack(webcam);
          setBackground(webcam);
          canvas.renderAll();
        });
      }, 1000 / frameRate);
    }

    return () => clearTimeout(frameRateTimeout);
  }, [useCamera, canvas, webcamRef, background]);

  // On resize
  useEffect(() => {
    if (background && !useCamera) {
      canvas.setWidth(canvasWidth);
      canvas.setHeight(canvasHeight);
      const verticallyConstrained =
        canvasWidth / canvasHeight < background.width / background.height;
      if (verticallyConstrained) {
        background.scaleToHeight(canvasHeight);
        background.lockMovementY = true;
        background.lockMovementX = false;
        background.set({
          top: 0,
          left: -(background.getScaledWidth() - canvas.width) / 2,
        });
      } else {
        background.scaleToWidth(canvasWidth);
        background.lockMovementX = true;
        background.lockMovementY = false;
        background.set({ top: 0, left: 0 });
      }
    }
  }, [canvasHeight, canvasWidth]);

  const onRotate = () => {
    // TODO: Rethink this, it's better than before but it's still lossy.
    const originalDimensions = background.getOriginalSize();
    background
      .scaleToHeight(originalDimensions.height)
      .scaleToWidth(originalDimensions.width)
      .rotate(90);
    canvas.remove(background);
    background.cloneAsImage((bg) => {
      bg.originY = "top";
      bg.originX = "left";
      const verticallyConstrained =
        canvas.width / canvas.height < bg.width / bg.height;
      if (verticallyConstrained) {
        console.log("vertical constraint");
        bg.scaleToHeight(canvas.height);
        bg.lockMovementY = true;
        bg.set({ top: 0, left: -(bg.getScaledWidth() - canvas.width) / 2 });
      } else {
        console.log("horizontal constraint");
        bg.scaleToWidth(canvas.width);
        bg.lockMovementX = true;
      }
      // Disable Scaling and rotating on the background
      bg.lockScalingX = true;
      bg.lockScalingY = true;
      bg.lockRotation = true;
      bg.hasControls = false;
      setBackground(bg);
      canvas.add(bg);
      bg.on("mouseup", onSelectBackground);
      canvas.on("object:moving", (e) => {
        // Event handler for moving the background
        if (e.target !== bg) return;
        const verticallyConstrained =
          canvas.width / canvas.height < bg.width / bg.height;
        if (verticallyConstrained) {
          console.log("vertically constrained movement");
          if (e.target.left > 0) {
            // If the user is trying to get the left from the right, prevent it
            e.target.left = 0;
          } else if (
            e.target.left < -(e.target.getScaledWidth() - canvas.width)
          ) {
            // If the user is trying to get the right from the left, prevent it
            e.target.left = -(e.target.getScaledWidth() - canvas.width);
          }
        } else {
          console.log("horizontally constrained movement");
          if (e.target.top > 0) {
            // If the user is trying to get the top down, prevent it
            e.target.top = 0;
          } else if (
            e.target.top < -(e.target.getScaledHeight() - canvas.height)
          ) {
            // If the user is trying to get the bottom up, prevent it
            e.target.top = -(e.target.getScaledHeight() - canvas.height);
          }
        }
        // Apply deltas to all lamps if any
        const deltaX = e.target.left - backgroundLeft.current;
        const deltaY = e.target.top - backgroundTop.current;
        canvas.getObjects().forEach((obj) => {
          if (obj !== bg) {
            obj.left += deltaX;
            obj.top += deltaY;
            obj.setCoords();
          }
        });
        // Update new values
        backgroundLeft.current = e.target.left;
        backgroundTop.current = e.target.top;
      });
      canvas.renderAll();
    });
  };

  const addLamp = (lamp) => {
    lamp.originX = "center";
    lamp.originY = "center";
    lamp.set({
      top: canvas.height / 3 - lamp.getScaledHeight() / 2,
      left: canvas.width / 2,
    });
    //lamp.setCoords();
    // Lock scaling to allow only aspect ratio preserving scales, remove controls.
    //lamp.lockRotation = true;
    lamp.borderScaleFactor = 3;
    lamp.setControlsVisibility({
      bl: true,
      br: true,
      tl: true,
      tr: true,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      mtr: true,
    });
    lamp.on("selected", () => setCurrentLamp(lamp));
    setPlacedLamps([...placedLamps, lamp]);
    canvas.add(lamp);
    canvas.setActiveObject(lamp);
    canvas.renderAll();
    setShowFullPageLoader(false);
  };

  // Navbar State
  const [showFullPageLoader, setShowFullPageLoader] = useState(false);

  const reset = () => {
    setStep(0);
  };

  const goBack = () => {
    console.log("Going back");
    setStep(0);
  };

  const removeLamp = () => {
    const newPlacedLamps = placedLamps.filter((l) => l !== currentLamp);
    setPlacedLamps(placedLamps.filter((l) => l !== currentLamp));
    canvas.remove(currentLamp);
    canvas.renderAll();
    setCurrentLamp(null);
    if (newPlacedLamps.length === 0) setControlsOpen(false);
  };

  const cloneLamp = () => {
    currentLamp.clone(addLamp);
  };

  const reflectLamp = () => {
    currentLamp.flipX = !currentLamp.flipX;
    canvas.renderAll();
  };

  const rotateLamp = () => {
    currentLamp.rotate(currentLamp.angle + 90);
    currentLamp.straighten();
    canvas.renderAll();
  };

  const downloadCanvas = useRef(null);
  // Modal for iOS
  const [showIOSModal, setShowIOSModal] = useState(false);
  const [iOSDataURL, setIOSDataURL] = useState("");

  const shareSetup = async () => {
    setShowFullPageLoader(true);
    const scale = 4;
    const dataURL = canvas.toDataURL({
      multiplier: scale,
      top: background.top,
      left: background.left,
      width: background.getScaledWidth(),
      height: background.getScaledHeight(),
    });
    const canvasRes = await fetch(dataURL);
    const blob = await canvasRes.blob();
    if (isIOS) {
      // On iOS we show a modal with the image
      setShowIOSModal(true);
      setIOSDataURL(dataURL);
    } else if (isAndroid) {
      // Android, we share with Share API
      try {
        console.log("Trying to share!");
        const file = new File([blob], simulator.name + ".png", {
          type: blob.type,
        });
        const filesArray = [file];
        await navigator.share({
          text: `Mirá cómo ${
            placedLamps.length === 1
              ? "queda esta lámpara"
              : "quedan estas lámparas"
          } de ${simulator.name}. Hacé tu propia simulación en `,
          files: filesArray,
          title: simulator.name,
          url:
            process.env.REACT_APP_BASE_SIMULATOR_URL + simulator.simulator_url,
        });
      } catch (e) {
        console.log("Catching! Normal Download default!");
        console.log(e);
      }
    } else {
      // Desktop Trigger normal download
      const objURL = URL.createObjectURL(blob);
      downloadCanvas.current.href = objURL;
      downloadCanvas.current.download = simulator.name + ".png";
      downloadCanvas.current.click();
      URL.revokeObjectURL(objURL);
    }
    setShowFullPageLoader(false);
  };

  // Lamp Controls State
  const [controlsOpen, setControlsOpen] = useState(false);

  const onSelectBackground = () => {
    console.log("clicked background");
    setCurrentLamp(null);
    setControlsOpen(false);
  };

  return (
    <>
      <div>
        <LeukFullPageLoader show={showFullPageLoader} />
        <LeukSimulatorNavbar
          show={!showFullPageLoader}
          simulator={simulator}
          goBack={goBack}
          reset={reset}
          onShare={shareSetup}
          canShare={placedLamps.length > 0}
          active={currentLamp}
          onRemove={removeLamp}
          onCopy={cloneLamp}
          onReflect={reflectLamp}
          onRotate={rotateLamp}
          open={controlsOpen}
          onOpen={() => setControlsOpen((o) => !o)}
        />
        {<canvas className="leuk-canvas" id="canvas"></canvas>}
        {useCamera && (
          <Webcam
            ref={webcamRef}
            videoConstraints={videoConstraints}
            className="leuk-webcam"
            screenshotFormat="image/jpeg"
            forceScreenshotSourceSize="true"
          ></Webcam>
        )}
        <LeukSimulatorSelector
          simulator={simulator}
          filteredItemList={filteredItemList}
          currentItem={currentItem}
          currentLevel={currentLevel}
          selectItem={selectItem}
          resetSelectedItem={resetSelectedItem}
          loading={loading}
          searchTerm={searchTerm}
          filterCurrentList={filterCurrentList}
          canRotate={placedLamps.length === 0 && !useCamera}
          onRotate={onRotate}
          onOpen={() => setSelectorOpen((o) => !o)}
          open={selectorAutoOpen && selectorOpen}
          showSubCategoryInBreadcrumb={showSubCategoryInBreadcrumb}
        />
      </div>
      <a className="downloader" ref={downloadCanvas}></a>
      <Modal show={showIOSModal} onHide={() => setShowIOSModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="share-modal-title">
            Mantené presionado para compartir...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={iOSDataURL} className="share-modal-image" />
        </Modal.Body>
      </Modal>
    </>
  );
}
