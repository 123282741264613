import React from "react";
import leukAPI from "../../api/leukAPI";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Spinner } from "react-bootstrap";
import AdminPage from "../components/AdminPage";
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import ActionButton from "../../components/ActionButton/ActionButton";
import SearchBar from "../../components/SearchBar/SearchBar";

export default function Categories() {
  const [categories, setCategories] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const history = useHistory();

  useEffect(() => {
    leukAPI.get("categories").then((response) => setCategories(response.data));
  }, []);

  const loadPage = (url) => {
    leukAPI.get(url).then((response) => setCategories(response.data));
  };

  const deleteCategory = (id) => {
    // delete and refresh
    setCategories(false);
    leukAPI.delete(`categories/${id}`).then((res) => {
      leukAPI
        .get("categories")
        .then((response) => setCategories(response.data));
    });
  };

  const onSearchAction = (value) => {
    setSearching(true);
    if (value !== "") {
      // Search for value
      setSearchParam(value);
      leukAPI.get(`categories/search?name=${value}`).then((response) => {
        setCategories(response.data);
        setSearching(false);
      });
    } else {
      // Reload first page
      setSearchParam("");
      leukAPI.get("categories").then((response) => {
        setCategories(response.data);
        setSearching(false);
      });
    }
  };

  const makeContent = () => {
    return (
      categories && (
        <>
          <h1>Categorías</h1>
          <ActionButton
            onClick={() => history.push("categories/new")}
          ></ActionButton>
          <Row className="justify-content-center mb-2 mt-5">
            <Col md={5}>
              <SearchBar onSearchAction={onSearchAction} />
            </Col>
          </Row>
          {!searching ? (
            <DynamicTable
              data={categories.data.map((category) => {
                return {
                  id: category.id,
                  title: category.name,
                  subtitle: `${category.sub_categories_count} Sub Categorías - ${category.lamps_count} lámparas`,
                  avatar: category.image_url,
                };
              })}
              meta={categories}
              parentRoute="categories"
              resourceLabel="Categoría"
              associatedResourceMessage="Se borrarán las Sub Categorías y Lámparas asociadas a esta Categoría."
              handleDeleteResource={deleteCategory}
              onPageSelected={loadPage}
              searchParam={searchParam}
            ></DynamicTable>
          ) : (
            <Spinner
              animation="grow"
              variant="dark"
              size="xl"
              className="spinner"
            />
          )}
        </>
      )
    );
  };

  return <AdminPage>{makeContent()}</AdminPage>;
}
