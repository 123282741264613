import React, { useState, useRef, useEffect } from "react";
import LeukSimulatorStep1 from "../LeukSimulatorStep1/LeukSimulatorStep1";
import LeukSimulatorStep2 from "../LeukSimulatorStep2/LeukSimulatorStep2";
import simulatorAPI from "../../api/simulatorAPI";

export default function LeukSimulator({ simulator }) {
  const [step, setStep] = useState(0);
  const [backgroundURL, setBackgroundURL] = useState("");
  const [useCamera, setUseCamera] = useState(false);
  const uploadedImage = useRef(null);

  // Load data
  const [spaces, setSpaces] = useState(false);
  useEffect(() => {
    simulatorAPI.get("spaces").then((res) => setSpaces(res.data));
  }, []);

  const onImageSelected = (imageUrl) => {
    setUseCamera(false);
    setBackgroundURL(imageUrl);
    setStep(1);
  };

  const onCameraSelected = () => {
    setUseCamera(true);
    setStep(1);
  };

  const makeCurrentStep = () => {
    switch (step) {
      case 0:
        return (
          <LeukSimulatorStep1
            simulator={simulator}
            uploadedImage={uploadedImage}
            onImageSelected={onImageSelected}
            onCameraSelected={onCameraSelected}
            spaces={spaces}
          />
        );
      case 1:
        return (
          <LeukSimulatorStep2
            simulator={simulator}
            backgroundURL={backgroundURL}
            useCamera={useCamera}
            setStep={setStep}
          />
        );
      default:
        return;
    }
  };

  return makeCurrentStep();
}
