import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  const tokenValue =  "Bearer " + (localStorage.getItem("token") || "");
  config.headers.Authorization = tokenValue;
  config.headers['X-Authorization'] =  tokenValue;
  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      console.log("unauthorized!");
      if (window.location.hash !== "#/login") {
        console.log("redirecting!");
        window.location.href = "#/login";
      }
      return Promise.reject(error);
    }
  }
);

export default client;
