import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  ButtonGroup,
  Button,
  Modal,
} from "react-bootstrap";
import {
  PencilSquare,
  Trash,
  ArrowLeftCircle,
  ArrowRightCircle,
} from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import "./DynamicTable.css";

export default function DynamicTable({
  data,
  meta,
  parentRoute,
  resourceLabel,
  associatedResourceMessage = "",
  handleDeleteResource,
  onPageSelected,
  searchParam = "",
}) {
  const history = useHistory();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [idToDelete, setIdToDelete] = useState(null);

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleConfirmation = (id, title) => {
    setConfirmationMessage(
      `Está segure de borrar la ${resourceLabel} ${title}? ${associatedResourceMessage}`
    );
    setIdToDelete(id);
    setShow(true);
  };

  const handleDelete = () => {
    handleDeleteResource(idToDelete);
    handleClose();
  };

  const makeItem = ({ id, title, subtitle, avatar, customActions = null }) => {
    return (
      <Row className="list-item align-items-center" key={id}>
        <Col xs={2}>
          <Image
            src={
              avatar.includes("http")
                ? avatar
                : process.env.REACT_APP_FILE_SERVER + avatar
            }
            roundedCircle
          />
        </Col>
        <Col xs={6}>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </Col>
        <Col xs={4} className="justify-content-center">
          <ButtonGroup aria-label="Actions" className="item-controls">
            {customActions}
            <Button
              variant="success"
              onClick={() => history.push(`/${parentRoute}/${id}`)}
            >
              <PencilSquare />
            </Button>
            <Button
              variant="danger"
              onClick={() => handleConfirmation(id, title)}
            >
              <Trash />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    );
  };

  const makePageControls = () => {
    return (
      <Row className="justify-content-center pagination-controls">
        <ButtonGroup>
          <Button
            key={meta.links[0].label}
            variant="light"
            disabled={meta.links[0].url == null}
            onClick={() =>
              onPageSelected(
                meta.links[0].url +
                  (searchParam !== "" ? `&name=${searchParam}` : "")
              )
            }
          >
            <ArrowLeftCircle />
          </Button>
          {meta.links.slice(1, -1).map((link) => {
            return (
              <Button
                key={link.label}
                variant={`light ${link.active ? "selected" : ""}`}
                className="numeric-page"
                disabled={link.active || link.url === null}
                onClick={() =>
                  onPageSelected(
                    link.url +
                      (searchParam !== "" ? `&name=${searchParam}` : "")
                  )
                }
              >
                {link.label}
              </Button>
            );
          })}
          <Button
            key={meta.links[meta.links.length - 1].label}
            variant="light"
            disabled={meta.links[meta.links.length - 1].url == null}
            onClick={() =>
              onPageSelected(
                meta.links[meta.links.length - 1].url +
                  (searchParam !== "" ? `&name=${searchParam}` : "")
              )
            }
          >
            <ArrowRightCircle />
          </Button>
        </ButtonGroup>
      </Row>
    );
  };

  return (
    <Container>
      {makePageControls()}
      {data.length !== 0 ? (
        data.map((item) => {
          return makeItem(item);
        })
      ) : (
        <h3>No hay resultados para mostrar.</h3>
      )}
      {makePageControls()}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmationMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="dark" onClick={handleDelete}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
