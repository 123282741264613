import React, { useEffect, useState } from "react";
import simulatorAPI from "../../api/simulatorAPI";
import LeukSimulator from "../../components/LeukSimulator/LeukSimulator";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import useGaTracker from "../../hooks/useGATracker";

export default function LeukSimulatorPage() {
  const { slug } = useParams();
  const [simulator, setSimulator] = useState(false);
  useGaTracker();
  useEffect(() => {
    simulatorAPI
      .get(`slug/${slug}`)
      .then((response) => setSimulator(response.data));
  }, [slug]);

  return simulator ? (
    <LeukSimulator simulator={simulator} />
  ) : (
    <Spinner animation="grow" variant="dark" size="xl" className="spinner" />
  );
}
