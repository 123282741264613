import React from "react";
import AdminPage from "../components/AdminPage";
import leukAPI from "../../api/leukAPI";
import { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Image, Modal, Alert } from "react-bootstrap";
import {
  CloudUpload,
  Trash,
  CardImage,
  QuestionLg,
} from "react-bootstrap-icons";
import { useParams, useHistory } from "react-router-dom";
import "./Simulator.css";

export default function Simulator() {
  const { id } = useParams();
  const history = useHistory();
  const [simulator, setSimulator] = useState(false);
  const [name, setName] = useState("");
  const [url, setURL] = useState("");
  const [mainColor, setMainColor] = useState("#000000");
  const [secondaryColor, setSecondaryColor] = useState("#000000");
  const [image, setImage] = useState(null);
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const showSuccessMessage = (message) => {
    setAlertMessage(message);
    setAlertVariant("success");
    setShowAlert(true);
  };

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearInputs = () => {
    setImage(false);
    setName("");
    setURL("");
    setMainColor("#000000");
    setSecondaryColor("#000000");
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
        setImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id === "new") {
      if (
        !name ||
        !mainColor ||
        !secondaryColor ||
        !url ||
        !image ||
        uploadedImage.current.file.type !== "image/png"
      ) {
        handleShow();
        return;
      }
      let data = new FormData();
      data.set(
        "image_file",
        uploadedImage.current.file,
        uploadedImage.current.file.name
      );
      data.set("name", name);
      data.set("main_color", mainColor);
      data.set("secondary_color", secondaryColor);
      data.set("url", url);

      // create a new lamp
      leukAPI.post("simulators", data).then((res) => {
        if (res.status === 201) {
          showSuccessMessage("Lámpara añadida con éxito!");
          clearInputs();
        }
      });
    } else {
      // Update an existing one
      if (
        !name ||
        !mainColor ||
        !secondaryColor ||
        !url ||
        !image ||
        (uploadedImage.current.file &&
          uploadedImage.current.file.type !== "image/png")
      ) {
        handleShow();
        return;
      }
      let data = new FormData();
      if (uploadedImage.current.file) {
        data.set(
          "image_file",
          uploadedImage.current.file,
          uploadedImage.current.file.name
        );
        // reset the uploaded image so that it doesn't get re uploaded if more changes are done
        uploadedImage.current.file = undefined;
      }
      data.set("name", name);
      data.set("main_color", mainColor);
      data.set("secondary_color", secondaryColor);
      data.set("url", url);
      leukAPI.post(`simulators/${id}`, data).then((res) => {
        if (res.status === 200) {
          setSimulator(res.data);
          setName(res.data.name);
          setMainColor(res.data.main_color);
          setSecondaryColor(res.data.secondary_color);
          setURL(res.data.url);
          showSuccessMessage("Simulador editado con éxito!");
        }
      });
    }
  };

  useEffect(() => {
    if (id === "new") {
      setSimulator(true);
      setImage(false);
      return;
    }
    leukAPI.get(`simulators/${id}`).then((response) => {
      setSimulator(response.data);
      setName(response.data.name);
      setMainColor(response.data.main_color);
      setSecondaryColor(response.data.secondary_color);
      setURL(response.data.url);
      setImage(true);
    });
  }, [id]);

  const makeContent = () => {
    return simulator ? (
      <>
        <Row>
          <Col>
            <h1>{simulator === true ? "Nuevo Simulador" : simulator.name}</h1>
            {showAlert && (
              <Alert
                variant={alertVariant}
                onClose={() => setShowAlert(false)}
                dismissible
              >
                {alertMessage}
              </Alert>
            )}
          </Col>
        </Row>
        <Form>
          <Form.Row>
            <Form.Group as={Col} className="image-container" md={6}>
              <Image
                src={
                  simulator.logo && simulator.logo.includes("http")
                    ? simulator.logo
                    : process.env.REACT_APP_FILE_SERVER + simulator.logo
                }
                ref={uploadedImage}
                className={!image ? "hide" : ""}
              ></Image>

              <div className={image ? "hide" : "image-placeholder"}>
                <div>
                  <CardImage size={100} />
                  <QuestionLg size={100} />
                </div>

                <div className="image-placeholder-message">No hay imagen</div>
              </div>

              <div className="image-controls">
                <input
                  type="file"
                  accept="image/png"
                  multiple={false}
                  ref={imageUploader}
                  onChange={handleImageUpload}
                ></input>
                <Button onClick={() => imageUploader.current.click()}>
                  <CloudUpload></CloudUpload> Subir
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    setImage(null);
                    imageUploader.current.value = null;
                  }}
                >
                  <Trash></Trash> Borrar
                </Button>
              </div>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  value={url}
                  onChange={(e) => setURL(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Row}>
                <Col>
                  <Form.Label>Color Principal</Form.Label>
                  <Form.Control
                    type="color"
                    value={mainColor}
                    onChange={(e) => setMainColor(e.target.value)}
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Label>Color Secundario</Form.Label>
                  <Form.Control
                    type="color"
                    value={secondaryColor}
                    onChange={(e) => setSecondaryColor(e.target.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Button
                variant="dark"
                className="float-right"
                type="submit"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
              <Button
                variant="light"
                className="float-right mr-2"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Es necesario proveer una imagen .png, nombre, URL, color principal y
            secundario válidos para cargar una lámpara.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    ) : (
      false
    );
  };

  return <AdminPage>{makeContent()}</AdminPage>;
}
