import React, { useEffect, useState, useCallback } from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import leukAPI from "../../api/leukAPI";
import ThreeLayeredSelector from "../ThreeLayeredSelector/ThreeLayeredSelector";
import SearchBar from "../SearchBar/SearchBar";
import "./InventoryManager.css";

export default function InventoryManager({ simulator_id }) {
  const [categories, setCategories] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  const splitCategories = (data) => {
    const active = data
      .map((category) => {
        return {
          ...category,
          sub_categories: category.sub_categories
            .map((subCategory) => {
              return {
                ...subCategory,
                lamps: subCategory.lamps.filter((lamp) => lamp.active),
              };
            })
            .filter((subCategory) => subCategory.lamps.length > 0),
        };
      })
      .filter((category) => category.sub_categories.length > 0);

    const available = data
      .map((category) => {
        return {
          ...category,
          sub_categories: category.sub_categories
            .map((subCategory) => {
              return {
                ...subCategory,
                lamps: subCategory.lamps.filter((lamp) => !lamp.active),
              };
            })
            .filter((subCategory) => subCategory.lamps.length > 0),
        };
      })
      .filter((category) => category.sub_categories.length > 0);

    return [active, available];
  };

  const countLamps = (categories) =>
    categories.reduce(
      (total, category) =>
        total +
        category.sub_categories.reduce(
          (total, subCategory) => total + subCategory.lamps.length,
          0
        ),
      0
    );

  const loadLamps = useCallback(() => {
    setLoading(true);
    leukAPI
      .get(`/simulators/${simulator_id}/categories?all=deep`)
      .then((response) => {
        const [newActiveCategories, newAvailableCategories] = splitCategories(
          response.data
        );
        setActiveCategories(newActiveCategories);
        setAvailableCategories(newAvailableCategories);
        setCategories(response.data);
        setSearchTerm("");
        setLoading(false);
      });
  }, [simulator_id]);

  const deleteLamps = (lamps) => {
    setLoading(true);
    leukAPI
      .delete(`/simulators/${simulator_id}/lamps`, { data: { lamps } })
      .then((response) => loadLamps());
  };

  const addLamps = (lamps) => {
    setLoading(true);
    leukAPI
      .post(`/simulators/${simulator_id}/lamps`, { lamps })
      .then((response) => loadLamps());
  };

  useEffect(() => loadLamps(), [simulator_id, loadLamps]);

  return (
    <div>
      {!loading && categories.length > 0 ? (
        <>
          <Row className="justify-content-center mb-4">
            <Col lg={6}>
              <SearchBar
                onSearchAction={setSearchTerm}
                placeholder="Filtrar lámparas"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Inventario</h3>
              <p>
                <strong>Lámparas disponibles:</strong>&nbsp;
                {countLamps(availableCategories)}
              </p>
              <ThreeLayeredSelector
                categories={availableCategories}
                searchTerm={searchTerm}
                onSubmit={addLamps}
                buttonChildren={
                  <>
                    Agregar Seleccion <ChevronRight />
                  </>
                }
                emptyMessage="Todas las lámparas han sido asignadas."
              ></ThreeLayeredSelector>
            </Col>
            <Col>
              <h3>Simulador</h3>
              <p>
                <strong>Lámparas activas:</strong>&nbsp;
                {countLamps(activeCategories)}
              </p>
              <ThreeLayeredSelector
                categories={activeCategories}
                searchTerm={searchTerm}
                onSubmit={deleteLamps}
                buttonChildren={
                  <>
                    <ChevronLeft /> Eliminar Selección
                  </>
                }
                emptyMessage="No hay lámparas asignadas."
              ></ThreeLayeredSelector>
            </Col>
          </Row>
        </>
      ) : loading ? (
        <Spinner
          animation="grow"
          variant="dark"
          size="xl"
          className="spinner"
        />
      ) : (
        <h3>No hay lámparas cargadas</h3>
      )}
    </div>
  );
}
