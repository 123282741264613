import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Alert,
} from "react-bootstrap";
import "./Login.css";
import leukAPI from "../../api/leukAPI";
import { Redirect } from "react-router-dom";

export default function Login() {
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );

  const tryLogIn = (e) => {
    e.preventDefault();
    const logInData = Object.fromEntries(new FormData(e.target).entries());
    leukAPI
      .post("login", logInData)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        setIsLoggedIn(true);
      })
      .catch((e) => setShowLoginAlert(true));
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Container>
      <Row className="justify-content-center">
        <Col sm={6} xs={10} className="login-form-container">
          <Image src="/leuklogo.png" />
          <div>
            <h1>Leuk Simulador</h1>
            <h2>Login</h2>
            <Alert variant="danger" transition show={showLoginAlert}>
              Credenciales incorrectas!
            </Alert>
            <Form onSubmit={tryLogIn}>
              <Form.Group controlId="userName">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="admin@leukiluminacion.com.ar"
                  name="email"
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                />
              </Form.Group>

              <Button variant="dark" className="float-right" type="submit">
                Log in
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
