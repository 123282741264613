import React from "react";
import { Check } from "react-bootstrap-icons";
import "./CheckBox.css";

export default function CheckBox({ className, checked, onChecked }) {
  return (
    <div
      className={`checkbox ${checked ? "checked" : ""} ${className}`}
      onClick={onChecked}
    >
      {checked && <Check />}
      <input type="hidden" value={checked} />
    </div>
  );
}
