import React from "react";
import { Spinner } from "react-bootstrap";

export default function LeukFullPageLoader({ show }) {
  return (
    show && (
      <div className="leuk-full-page-loader">
        <Spinner
          animation="grow"
          variant="dark"
          size="lg"
          className="spinner"
        />
      </div>
    )
  );
}
