import React from "react";
import { Row, Col } from "react-bootstrap";
import LeukCard from "../../components/LeukCard/LeukCard";
import {
  Diagram2,
  Diagram3,
  Lightbulb,
  Images,
  Laptop,
} from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import AdminPage from "../components/AdminPage";

export default function Home() {
  const history = useHistory();

  const makeContent = () => {
    return (
      <>
        <Row>
          <Col>
            <h1>Home</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <LeukCard
              icon={<Diagram2 />}
              title="Categorías"
              action={() => history.push("/categories")}
            />

            <LeukCard
              icon={<Lightbulb />}
              title="Lámparas"
              action={() => history.push("/lamps")}
            />
          </Col>
          <Col xs={6}>
            <LeukCard
              icon={<Diagram3 />}
              title="Sub Categorías"
              action={() => history.push("/subcategories")}
            />
            <LeukCard
              icon={<Images />}
              title="Espacios"
              action={() => history.push("/spaces")}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={6}>
            <LeukCard
              icon={<Laptop />}
              title="Simuladores"
              action={() => history.push("/simulators")}
            />
          </Col>
        </Row>
      </>
    );
  };

  return <AdminPage>{makeContent()}</AdminPage>;
}
