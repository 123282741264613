import React, { useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";

export default function SearchBar({ onSearchAction, placeholder = null }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  const clearSearch = () => {
    setSearchTerm("");
    setHasSearched(false);
    onSearchAction("");
  };

  const makeSearch = () => {
    setHasSearched(true);
    onSearchAction(searchTerm);
  };

  return (
    <InputGroup>
      <Form.Control
        type="text"
        placeholder={placeholder ?? "Buscar..."}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      ></Form.Control>
      <InputGroup.Append>
        <Button variant="dark" onClick={makeSearch}>
          Buscar
        </Button>
        {hasSearched ? (
          <Button variant="light" onClick={clearSearch}>
            Limpiar
          </Button>
        ) : null}
      </InputGroup.Append>
    </InputGroup>
  );
}
