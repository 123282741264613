import React from "react";
import "./LeukCard.css";

export default function LeukCard({ icon, title, description, action }) {
  return (
    <div className="leuk-card" onClick={action}>
      <div className="leuk-card-icon">{icon}</div>
      <p className="leuk-card-title">{title}</p>
      {/* <p className="leuk-card-description">{description}</p> */}
    </div>
  );
}
