import { HashRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home/Home";
import Lamps from "./pages/Lamps/Lamps";
import SubCategories from "./pages/SubCategories/SubCategories";
import Categories from "./pages/Categories/Categories";
import Lamp from "./pages/Lamp/Lamp";
import Login from "./pages/Login/Login";
import SubCategory from "./pages/SubCategory/SubCategory";
import Category from "./pages/Category/Category";
import SimulatorLamp from "./pages/SimulatorLamp/SimulatorLamp";
import Spaces from "./pages/Spaces/Spaces";
import Space from "./pages/Space/Space";
import Simulators from "./pages/Simulators/Simulators";
import Simulator from "./pages/Simulator/Simulator";
import LeukSimulator from "./pages/LeukSimulator/LeukSimulator";

function App() {
  return (
    <>
      <HashRouter>
        <Switch>
          <Route path="/simulador/:slug" exact component={LeukSimulator} />
          <Route path="/login" exact component={Login} />
          <Route path="/" exact component={Home} />
          <Route path="/lamps" exact component={Lamps} />
          <Route path="/lamps/:id" exact component={Lamp} />
          <Route path="/lamps/new" exact component={Lamp} />
          <Route path="/subcategories" exact component={SubCategories} />
          <Route path="/subcategories/:id" exact component={SubCategory} />
          <Route path="/subcategories/new" exact component={SubCategory} />
          <Route path="/categories" exact component={Categories} />
          <Route path="/categories/:id" exact component={Category} />
          <Route path="/categories/new" exact component={Category} />
          <Route path="/spaces/" exact component={Spaces} />
          <Route path="/spaces/:id" exact component={Space} />
          <Route path="/spaces/new" exact component={Space} />
          <Route path="/simulators" exact component={Simulators} />
          <Route path="/simulators/:id" exact component={Simulator} />
          <Route path="/simulators/new" exact component={Simulator} />
          <Route path="/simulators/:id/lamps" exact component={SimulatorLamp} />
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
