import React, { useRef, useState } from "react";
import LeukSimulatorNavbar from "../LeukSimulatorNavbar/LeukSimulatorNavbar";
import { Camera, Upload, CameraFill, CardImage } from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";
import "./LeukSimulatorStep1.css";
import SpacesCarousel from "../SpacesCarousel/SpacesCarousel";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function LeukSimulatorStep1({
  simulator,
  uploadedImage,
  onImageSelected,
  onCameraSelected,
  spaces,
}) {
  const imageUploader = useRef(null);
  const [showCarousel, setShowCarousel] = useState(true);
  const { height } = useWindowDimensions();
  const [showMobileOverlay, setShowMobileOverlay] = useState(false);
  const containerHeight = height - 65;

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
        onImageSelected(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const colorStyles = {
    backgroundColor: simulator.main_color,
    color: simulator.secondary_color,
  };

  const onSelectBackground = () => {
    if (isMobile) {
      setShowMobileOverlay(true);
      setShowCarousel(false);
      // Hack for the carousel
      setTimeout(() => setShowCarousel(true), 10);
    } else {
      imageUploader.current.click();
    }
  };

  const onCloseOverlay = () => {
    setShowMobileOverlay(false);
    setShowCarousel(false);
    // Hack for the carousel
    setTimeout(() => setShowCarousel(true), 10);
  };

  return (
    <>
      <LeukSimulatorNavbar simulator={simulator} />
      <div className="leuk-container" style={{ height: containerHeight }}>
        <div>
          <h1 className="step1-title">Simulador Virtual</h1>
          <button
            style={colorStyles}
            className="step1-btn"
            onClick={onSelectBackground}
          >
            {isMobile ? <Camera /> : <Upload />}
            <input
              type="file"
              accept="image/png,image/jpeg,image/png"
              multiple={false}
              ref={imageUploader}
              onChange={handleImageUpload}
            ></input>
          </button>
          <img ref={uploadedImage} className="image-input" />
          <h1 className="step1-subtitle">Ver en mi habitación</h1>
        </div>
        <div>
          <h1 className="step1-ambients">O en nuestros ambientes</h1>
          {showCarousel && (
            <SpacesCarousel
              spaces={spaces}
              onImageSelected={onImageSelected}
              height={height}
            />
          )}
        </div>
      </div>
      <div
        className={`leuk-mobile-overlay ${showMobileOverlay ? "show" : ""}`}
        onClick={onCloseOverlay}
      >
        <div className="leuk-mobile-options">
          <div onClick={onCameraSelected}>
            <CameraFill /> Ver en vivo
          </div>
          <div onClick={() => imageUploader.current.click()}>
            <CardImage /> Usar una foto
          </div>
        </div>
      </div>
    </>
  );
}
