import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Accordion,
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import CheckBox from "../CheckBox/CheckBox";
import "./ThreeLayeredSelector.css";

export default function ThreeLayeredSelector({
  categories,
  searchTerm,
  onSubmit,
  buttonChildren,
  emptyMessage,
}) {
  const [selectedLamps, setSelectedLamps] = useState(new Set());
  const [selectedSubCategories, setSelectedSubCategories] = useState(new Set());
  const [selectedCategories, setSelectedCategories] = useState(new Set());

  const updateCategorySelection = (id) => {
    const newSelectedLamps = new Set(selectedLamps);
    const newSelectedCategories = new Set(selectedCategories);
    const newSelectedSubCategories = new Set(selectedSubCategories);

    const category = categories.filter((category) => category.id === id)[0];
    // If not selected, mark me everything downstream as selected
    if (!selectedCategories.has(id)) {
      newSelectedCategories.add(id);
      category.sub_categories.forEach((subCategory) => {
        newSelectedSubCategories.add(subCategory.id);
        subCategory.lamps.forEach((lamp) => {
          newSelectedLamps.add(lamp.id);
        });
      });
    } else {
      // If selected, remove me and everything downstream
      newSelectedCategories.delete(id);
      category.sub_categories.forEach((subCategory) => {
        newSelectedSubCategories.delete(subCategory.id);
        subCategory.lamps.forEach((lamp) => {
          newSelectedLamps.delete(lamp.id);
        });
      });
    }
    setSelectedCategories(newSelectedCategories);
    setSelectedSubCategories(newSelectedSubCategories);
    setSelectedLamps(newSelectedLamps);
  };

  const updateSubCategorySelection = (id, category_id) => {
    const newSelectedLamps = new Set(selectedLamps);
    const newSelectedSubCategories = new Set(selectedSubCategories);

    const subCategory = categories
      .filter((category) => category.id === category_id)[0]
      .sub_categories.filter((subCategory) => subCategory.id === id)[0];
    // If not selected, mark me everything downstream as selected
    if (!selectedSubCategories.has(id)) {
      newSelectedSubCategories.add(id);

      subCategory.lamps.forEach((lamp) => {
        newSelectedLamps.add(lamp.id);
      });
    } else {
      // If selected, remove me and everything downstream
      newSelectedSubCategories.delete(id);

      subCategory.lamps.forEach((lamp) => {
        newSelectedLamps.delete(lamp.id);
      });
    }
    setSelectedSubCategories(newSelectedSubCategories);
    setSelectedLamps(newSelectedLamps);
  };

  const updateLampSelection = (id) => {
    const newSelectedLamps = new Set(selectedLamps);
    if (newSelectedLamps.has(id)) {
      newSelectedLamps.delete(id);
    } else {
      newSelectedLamps.add(id);
    }
    setSelectedLamps(newSelectedLamps);
  };

  const makeLamp = ({ id, name }) => {
    return (
      <div key={id} className="item pl-4">
        <CheckBox
          className="mr-2 ml-4"
          checked={selectedLamps.has(id)}
          onChecked={() => updateLampSelection(id)}
        />
        <strong>Lámpara:</strong>&nbsp;{name}
      </div>
    );
  };

  const makeSubCategory = ({ id, category_id, name, lamps }) => {
    return (
      <Card key={id}>
        <Card.Header className="header">
          <div className="header-title pl-4">
            <CheckBox
              className="mr-2"
              checked={selectedSubCategories.has(id)}
              onChecked={() => updateSubCategorySelection(id, category_id)}
            />
            <strong>Sub Categoría:</strong>&nbsp;{name}
          </div>
          <ContextAwareToggle eventKey={id} />
        </Card.Header>
        <Accordion.Collapse eventKey={id}>
          <Card.Body className="pl-4">{lamps.map(makeLamp)}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  const makeCategory = ({ name, id, sub_categories }) => {
    return (
      <Card key={id}>
        <Card.Header className="header">
          <div className="header-title">
            <CheckBox
              className="mr-2"
              checked={selectedCategories.has(id)}
              onChecked={() => updateCategorySelection(id)}
            />
            <strong>Categoría:</strong>&nbsp;{name}
          </div>
          <ContextAwareToggle eventKey={id} />
        </Card.Header>
        <Accordion.Collapse eventKey={id}>
          <Card.Body>
            <Accordion key={id} className="sub-category">
              {sub_categories.map(makeSubCategory)}
            </Accordion>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  const [effectiveCategories, setEffectiveCategories] = useState([
    ...categories,
  ]);
  const updateEffectiveCategories = useCallback(() => {
    if (searchTerm !== "") {
      // Remove all lamps that don't match, emtpy sub categories and categories.
      const filteredCategories = categories
        .map((category) => {
          return {
            ...category,
            sub_categories: category.sub_categories
              .map((subCategory) => {
                return {
                  ...subCategory,
                  lamps: subCategory.lamps.filter((lamp) =>
                    lamp.name.toLowerCase().includes(searchTerm.toLowerCase())
                  ),
                };
              })
              .filter((subCategory) => subCategory.lamps.length > 0),
          };
        })
        .filter((category) => category.sub_categories.length > 0);
      setEffectiveCategories(filteredCategories);
    } else {
      // Clear search
      setEffectiveCategories([...categories]);
    }
  }, [searchTerm, categories]);

  useEffect(
    () => updateEffectiveCategories(),
    [searchTerm, updateEffectiveCategories]
  );

  return (
    <>
      <p>
        <strong>Lámparas seleccionadas</strong>: {selectedLamps.size}
      </p>

      <Button
        variant="dark"
        className="mb-4 mx-auto submit"
        disabled={selectedLamps.size === 0}
        onClick={() => onSubmit(Array.from(selectedLamps))}
      >
        {buttonChildren}
      </Button>
      {categories.length > 0 ? (
        effectiveCategories.length > 0 ? (
          <Accordion className="category">
            {effectiveCategories.map(makeCategory)}
          </Accordion>
        ) : (
          "No hay resultados para la búsqueda actual."
        )
      ) : (
        emptyMessage
      )}
    </>
  );
}

const ContextAwareToggle = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className={`toggle ${isCurrentEventKey ? "open" : ""}`}
      onClick={decoratedOnClick}
    >
      {isCurrentEventKey ? <ChevronUp /> : <ChevronDown />}
    </button>
  );
};
